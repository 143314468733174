import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["defaultPlan", "table"]

  initialize() {
    this.initializeToggleButton()
    this.defaultPlanTarget.click()

    let defaultPlanName = this.defaultPlanTarget.value
    this.showPlanByName(defaultPlanName)
  }

  initializeToggleButton() {
    const radioInputs = document.querySelectorAll('.toggle-input');
    const slider = document.querySelector('.slider');

    radioInputs.forEach(input => {
      input.addEventListener('change', function() {
        if (this.value === 'annual') {
          slider.classList.add('annual');
        } else {
          slider.classList.remove('annual');
        }
      });
    });
  }

  showPlan(e) {
    let planName = e.currentTarget.value
    this.showPlanByName(planName)
  }

  showPlanByName(planName) {
    let table = this.findTargetTable(planName) 
    this.tableTargets.forEach(t => t.classList.add("hidden"))
    table.classList.remove("hidden") 
  }

  findTargetTable(name) {
    return this.tableTargets.find(t => t.id == `table-${name}`)
  }
}

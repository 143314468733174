import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]
  static values = {
    currentIndex: { type: Number, default: 0}
  }

  connect() {
    this.showCurrentPanel()
    this.startAutoSlide()
  }

  show(event) {
    const selectedTab = event.target
    this.showTab(selectedTab)
    this.showPanel(selectedTab)

    this.currentIndexValue = this.tabIndex(selectedTab)
    this.stopAutoSlide()
    this.startAutoSlide(5000)
  }

  tabIndex(tab) {
    return this.tabTargets.findIndex(t => {
      return tab.dataset.additionalFeatureNameParam == t.dataset.additionalFeatureNameParam
    })
  }

  showCurrentPanel() {
    this.panelTargets.forEach((panel, index) => {
      if (index == this.currentIndexValue) {
        panel.classList.remove("hidden")
        requestAnimationFrame(() => {
          panel.classList.add('animate-fade-in')
        })
      } else {
        panel.classList.add("hidden")
        panel.classList.remove('animate-fade-in')
      }
    })
  }

  showCurrentTab() {
    this.tabTargets.forEach((tab, index) => {
      if (index == this.currentIndexValue) {
        tab.classList.add("tab-underline")
      } else {
        tab.classList.remove("tab-underline")
      }
    })
  }

  showTab(tab) {
    // remove underline for all tabs
    this.tabTargets.forEach(t => t.classList.remove("tab-underline"))
    
    // highlight underline for the selected tab
    tab.classList.add("tab-underline")

    // hide all panels
    this.panelTargets.forEach(p => p.classList.add("hidden"))  
  }

  showPanel(tab) {
    // show the panel with the id panel-<tab-name>
    let selectedPanelName = tab.dataset.additionalFeatureNameParam
    let targetPanel = document.getElementById(`panel-${selectedPanelName}`)
    targetPanel.classList.remove("hidden")

    requestAnimationFrame(() => {
      targetPanel.classList.add('animate-fade-in')
    })
  }

  next() {
    this.currentIndexValue = (this.currentIndexValue + 1) % this.panelTargets.length
    this.showCurrentTab()
    this.showCurrentPanel()
  }

  startAutoSlide(intervalValue=2500) {
    this.autoSlideTimer = setInterval(() => {
      this.next()
    }, intervalValue)
  }

  stopAutoSlide() {
    if (this.autoSlideTimer) {
      clearInterval(this.autoSlideTimer)
    }
  }

  // Pause auto-sliding on hover
  mouseEnter() {
    this.stopAutoSlide()
  }

  mouseLeave() {
    this.startAutoSlide()
  }
}


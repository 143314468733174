document.addEventListener("turbo:load", function(){
  const currentUrlPathname = window.location.pathname
  document.querySelectorAll("header nav ul li a").forEach(a => {
    let aUrl = new URL(a.href)
    if(aUrl.pathname != "/" && aUrl.pathname == currentUrlPathname) {
      console.log(aUrl.pathname)
      console.log("highlight it")
      a.classList.add("font-medium", "text-gray-700")
    }
  })
})
